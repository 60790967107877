import React from "react"

const BoxSection = ({ data }) => {
  const backgroundColor = data.backgroundColor === "dark" ? "grey" : "white"
  return (
    <div
      class={
        "section section--box section--background-color-" + backgroundColor
      }
    >
      <div class="section__container u-wrap u-wrap--small">
        <div class={"box box--background-color-" + backgroundColor}>
          <div class="box__heading-container">
            <h2 class="box__heading heading heading--medium">{data.title}</h2>
          </div>
          <div class="box__content box__content--center">
            <div
              class="box__text"
              dangerouslySetInnerHTML={{
                __html: data.body.childMarkdownRemark.html.replace(
                  "\n",
                  "<br/>"
                ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BoxSection
