import React from "react"
import Img from "gatsby-image"

const NonStretchedImage = props => {
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth,
        margin: "0 auto", // Used to center the image
      },
    }
  }

  return <Img {...normalizedProps} />
}

const TextSection = ({ data }) => {
  const backgroundColor = data.backgroundColor === "dark" ? "grey" : "white"
  return (
    <div
      id={data.slug}
      className={
        "section section--text section--background-color-" + backgroundColor
      }
    >
      <div className="section__container u-wrap u-wrap--medium">
        {data.title && (
          <h2 className="section__heading heading heading--small">
            {data.title}
          </h2>
        )}
        <div className="section__body">
          {data.image && (
            <NonStretchedImage
              fluid={data.image.fluid}
              alt={data.image.title}
            />
          )}
          {data.body && (
            <div
              dangerouslySetInnerHTML={{
                __html: data.body.childMarkdownRemark.html,
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default TextSection
