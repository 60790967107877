import React from "react"

import Shop from "../shop"

const ShopsSection = ({ data }) => {
  const backgroundColor = data.backgroundColor === "dark" ? "grey" : "white"
  return (
    <div
      id={data.slug}
      className={
        "section section--shops section--background-color-" + backgroundColor
      }
    >
      <div className="section__container u-wrap u-wrap--large">
        <h2 className="section__heading heading heading--small">
          {data.title}
        </h2>
        {/*
        <div className="order">
          <a className="order__button" href="/bestallning">
            Beställ för upphämtning
          </a>
        */}
        <ul className="section__items">
          {data.shops.map((shop, index) => {
            return (
              <Shop
                key={index}
                title={shop.title}
                openingHours={
                  shop.openingHours.childMarkdownRemark.rawMarkdownBody
                }
                shopAddress={shop.address.childMarkdownRemark.rawMarkdownBody}
                mapLink={shop.linkToMap}
                boxBackgroundColor={backgroundColor}
              />
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default ShopsSection
