import React from "react"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class ContactUsSection extends React.Component {
  state = {
    body: "",
    name: "",
    email: "",
    phone: "",
    message: "",
    acceptedTerms: false,
  }

  // prettier-ignore
  handleSubmit = event => {
    fetch("/?no-cache=1", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        "subject": "Meddelande från hemsidan",
        ...this.state,
      }),
    })
      .then(this.handleSuccess)
      .catch(error => alert(error))
    event.preventDefault()
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleInputClick = event => {
    this.setState({ acceptedTerms: event.target.checked })
  }

  handleSuccess = () => {
    this.setState({
      name: "",
      email: "",
      phone: "",
      message: "",
      acceptedTerms: false,
    })
    document.getElementById("form__response").classList.remove("form__hidden")
  }

  render() {
    const backgroundColor =
      this.props.backgroundColor === "dark" ? "grey" : "white"
    return (
      <div
        id={this.props.slug}
        className={
          "section section--contact-us section--background-color-" +
          backgroundColor
        }
      >
        <div className="section__container u-wrap u-wrap--medium">
          <div className={"box box--background-color-" + backgroundColor}>
            <div className="box__heading-container">
              <h2 className="box__heading heading heading--medium">
                {this.props.title}
              </h2>
            </div>
            <div className="box__content">
              <div className="box__text">
                {this.props.body && (
                  <div
                    className="box__paragraph"
                    dangerouslySetInnerHTML={{
                      __html: this.props.body,
                    }}
                  />
                )}
              {/*
                <div className="form form--background-color-grey">
                  <div className="form__order">
                    <a className="form__submit" href="/bestallning">
                      Beställ för upphämtning
                    </a>
                  </div>
                </div>
                <div
                  role="form"
                  className={"form form--background-color-" + backgroundColor}
                  lang="sv-SE"
                  dir="ltr"
                >
                  <form
                    id="contact-form"
                    name="contact"
                    data-netlify="true"
                    method="POST"
                    className="form__form"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="form__hidden">
                      <label>
                        Don’t fill this out if you're human:{" "}
                        <input
                          name="bot-field"
                          onChange={this.handleInputChange}
                        />
                      </label>
                      <input
                        type="text"
                        name="subject"
                        defaultValue="Meddelande från webbsidan"
                        className="form__hidden"
                      />
                    </div>
                    <label className="form__label">
                      {" "}
                      Ditt namn*
                      <br />
                      <input
                        type="text"
                        name="name"
                        size="40"
                        className="form__text"
                        value={this.state.name}
                        onChange={this.handleInputChange}
                        required
                      />
                    </label>
                    <label className="form__label">
                      {" "}
                      Din epost*
                      <br />
                      <input
                        type="email"
                        name="email"
                        size="40"
                        className="form__text form__text--email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        required
                      />
                    </label>
                    <label className="form__label">
                      {" "}
                      Telefonnummer
                      <br />
                      <input
                        type="tel"
                        name="phone"
                        size="40"
                        className="form__text form__text--phone"
                        value={this.state.phone}
                        onChange={this.handleInputChange}
                      />
                    </label>
                    <label className="form__label">
                      {" "}
                      Ditt ärende
                      <br />
                      <textarea
                        name="message"
                        cols="40"
                        rows="10"
                        className="form__textarea"
                        value={this.state.message}
                        onChange={this.handleInputChange}
                      />
                    </label>
                    <span className="form__terms">
                      <label className="form__label form__label--terms">
                        <input
                          type="checkbox"
                          name="acceptedTerms"
                          className="form__input"
                          checked={this.state.acceptedTerms}
                          onChange={this.handleInputClick}
                          required
                        />
                        <span className="form__terms-span">
                          {this.props.formTerms}
                        </span>
                      </label>
                    </span>
                    <div className="form__submit-container">
                      <input type="hidden" name="form-name" value="contact" />
                      <input
                        type="submit"
                        value="Skicka meddelande"
                        className="form__submit"
                        disabled=""
                      />
                    </div>

                    <div
                      id="form__response"
                      className="form__response form__hidden"
                    >
                      <p>{this.props.successMessage}</p>
                    </div>
                  </form>
                </div>
*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ({ data }) => (
  <ContactUsSection
    title={data.title}
    body={data.body.childMarkdownRemark.html}
    slug={data.slug}
    formTerms={data.formTerms.childMarkdownRemark.rawMarkdownBody}
    successMessage={data.successMessage.childMarkdownRemark.rawMarkdownBody}
    backgroundColor={data.backgroundColor}
  />
)
