import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"

import BoxSection from "../components/sections/box"
import ShopsSection from "../components/sections/shops"
import TextSection from "../components/sections/text"
import FullSizeImage from "../components/sections/fullsizeimage"
import ContactUsSection from "../components/sections/contactUs"

import OgImage from "../images/og-image.jpg"

const TemplatePage = ({ data: { contentfulPage }, location }) => {
  return (
    <Layout pagePath={location.pathname}>
      <SEO
        title={contentfulPage.title}
        ogImage={OgImage}
        pagePath={location.pathname}
      />
      <Hero
        title={contentfulPage.title}
        image={contentfulPage.heroImage}
        pagePath={location.pathname}
      />

      {contentfulPage.sections.map((section, index) => {
        switch (section.__typename) {
          case "ContentfulBoxSection":
            return <BoxSection key={index} data={section} />
          case "ContentfulShopsSection":
            return <ShopsSection key={index} data={section} />
          case "ContentfulImageAndTextSection":
            return <TextSection key={index} data={section} />
          case "ContentfulFullSizeImageSection":
            return <FullSizeImage key={index} data={section} />
          case "ContentfulContactUsSection":
            return <ContactUsSection key={index} data={section} />
          default:
            return null
        }
      })}
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      heroImage {
        title
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      sections {
        __typename
        ... on Node {
          ... on ContentfulBoxSection {
            title
            slug
            backgroundColor
            body {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulShopsSection {
            title
            slug
            backgroundColor
            shops {
              title
              openingHours {
                childMarkdownRemark {
                  rawMarkdownBody
                }
              }
              address {
                childMarkdownRemark {
                  rawMarkdownBody
                }
              }
              linkToMap
            }
          }
          ... on ContentfulImageAndTextSection {
            title
            slug
            backgroundColor
            image {
              title
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            body {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulFullSizeImageSection {
            title
            image {
              title
              fluid(maxWidth: 1920) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          ... on ContentfulContactUsSection {
            title
            body {
              childMarkdownRemark {
                html
              }
            }
            slug
            backgroundColor
            formTerms {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
            successMessage {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
        }
      }
    }
  }
`
export default TemplatePage
