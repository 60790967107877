import React from "react"
import Img from "gatsby-image"

const FullSizeImage = ({ data }) => {
  return (
    <div id={data.slug} className="section section--fullwidth-image">
      {data.image && (
        <>
          <Img
            fluid={data.image.fluid}
            alt={data.image.title}
            className="section__fullwidth-image-wrapper"
            style={{
              position: "absolute",
            }}
          />
          <div className="hero__overlay" />
        </>
      )}
      {data.title && (
        <div class="section__fullwidth-image-content u-wrap u-wrap--large">
          <span class="section__fullwidth-image-heading heading heading--xlarge">
            {data.title}
          </span>
        </div>
      )}
    </div>
  )
}

export default FullSizeImage
