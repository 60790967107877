import PropTypes from "prop-types"
import React from "react"

const BoxHeading = ({ children }) => (
  <div className="box__heading-container">
    <h3 className="box__heading heading heading--medium" itemProp="name">
      <span className="u-sr">Robin Delselius Bageri</span>
      {children}
    </h3>
  </div>
)

const OpeningHours = ({ children }) => (
  <div className="shop__opening-hours">
    <h4 className="shop__subtitle heading heading--xsmall">Öppettider</h4>
    <div>
      {children}
    </div>
  </div>
)

const ShopAddress = ({ shopAddress, mapLink }) => (
  <div className="shop__address">
    <h4 className="shop__subtitle heading heading--xsmall">Adress</h4>
    <div
      dangerouslySetInnerHTML={{ __html: shopAddress.replace("\n", "<br/>") }}
      itemProp="address"
      itemScope=""
      itemType="http://schema.org/PostalAddress"
    />
    <div className="shop__address-link-container">
      <a
        itemProp="hasMap"
        className="shop__address-link"
        href={mapLink}
        title="Hitta hit"
        target="_blank"
        rel="noopener noreferrer"
      >
        Hitta hit
      </a>
    </div>
  </div>
)

const Shop = ({
  title,
  openingHours,
  shopAddress,
  mapLink,
  boxBackgroundColor,
}) => (
  <li
    className={"shop box box--background-color-" + boxBackgroundColor}
    itemScope=""
    itemType="http://schema.org/CafeOrCoffeeShop"
  >
    <div className="shop__container">
      <BoxHeading>{title}</BoxHeading>
      <div className="box__content box__content--center box__content--justify">
        <div className="shop__top">
          <OpeningHours>{openingHours}</OpeningHours>
        </div>
        <div className="shop__bottom">
          <ShopAddress shopAddress={shopAddress} mapLink={mapLink} />
        </div>
      </div>
    </div>
  </li>
)

Shop.propTypes = {
  title: PropTypes.string,
  openingHours: PropTypes.string,
  shopAddress: PropTypes.string,
  mapLink: PropTypes.string,
}

Shop.defaultProps = {
  title: ``,
  openingHours: ``,
  shopAddress: ``,
  mapLink: ``,
}

export default Shop
